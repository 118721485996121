import React from "react"
import styles from "./styles.module.scss"
import Address from './address'
import EmailForm from './email-form'

export default () => 
	<div className={`${styles.gridContainerSM} ${styles.primaryTextColor} ${styles.pageContainer}`}>
		<div className={`${styles.letsTalk} ${styles.mb_3Rem}`}>
			<h1>Let's Talk</h1>
			<h3>Ask us anything or just say hi</h3>
		</div>
		<EmailForm styles={styles}/>
		<Address styles={styles}/>
	</div>