import React from 'react'
import {graphql, useStaticQuery} from "gatsby"

import Address from '../../images/address.svg'
import Mail from '../../images/mail.svg'
import Contact from '../../images/contact_no.svg'

const AD = ({styles}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiAddresses {
			    edges {
			      node {
			        items {
			          value
			          id
			          label
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiAddresses.edges.forEach(({node})=>{
		d.push(node)
	})
	d = d[0]
	return (
		<div className={styles.ourContactInfo}>	
			{
				d.items.map((item, index) => {
					return (
						<div className={styles.addressWrap} key={`ad_${index}`}>
							<img src={item.label === 'mail' ? Mail : item.label==='address' ? Address : Contact} alt={`Reviving wholeness-${item.label}`}/>
							<p className={index === 0 ? '' : (index ===1 ? styles.mb_2Rem : styles.wordBreak)} dangerouslySetInnerHTML={{__html: item.value}}/>
						</div>
					)
				})
			}
		</div>
	)
}

export default AD