import React from 'react'
import Toast from 'light-toast';
import emailjs from 'emailjs-com';

class Contact extends React.Component {
	state = {
		first_name : '',
		last_name: '',
		phone_number: '',
		email: '',
		message: '',
		errors: {
			first_name: null,
			phone_number: null,
			email: null,
			message: null
		},
		loading: true
	}

	handleSubmit(e) {
		e.preventDefault()
		let {errors} = this.state;
		for (let prop in this.state) {
			this.validation(prop, this.state[prop]);
		}
		for (let prop in this.state.errors) {
			if (this.state.errors[prop] !== null) {
				return 0;
			}
		}
		Toast.loading('Loading...');
		if(!this.state.loading){
			Toast.hide()
		}
		this.sendMail();
	}

	validation(name, value){
		let {errors} = this.state;
		if (Object.keys(this.state.errors).indexOf(name) > -1) {
			errors[name] = value.length < 1 ? `${name} field is required!` : null;
			if(name=== 'email') {
				errors['email'] = value.length < 1 ? 'email field is required!': this.isEmail(value) === false ? `valid email is required!` : null;
			}
			this.setState({ errors, [name]: value }, () => {
				//console.log(errors)
			})
		}
	}

	isEmail(email){
	  const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
	  return regex.test(email);
	}

	async sendMail(){
		try {
			let params = {
				name: `${this.state.first_name} ${this.state.last_name}`,
				email: this.state.email,
				phone: this.state.phone_number,
				message: this.state.message
			}
			await emailjs.send(process.env.EMAIL_SERVICE_ID, process.env.EMAIL_TEMPLATE_ID, params, process.env.EMAIL_USER_ID);
			this.setState({
				first_name: '',
				last_name: '',
				phone_number: '',
				email: '',
				message: '',
				loading: false
			})
			Toast.success('Email was sent successfully.', 5000);
		}
		catch(error){
			console.log(error)
		}
	}

	handleChange(e) {
		let name = e.target.name;
		let value = e.target.value
		this.setState({
			[name]: value
		})
		this.validation(name,value);
	}

	render(){
		const {styles} = this.props;
		const {errors} = this.state;

		return (
			<form onSubmit = {(e)=>this.handleSubmit(e)} className={`${styles.contactUsForm} ${styles.flexOnColsXS} ${styles.flexSM} ${styles.mb_3Rem}`}>
				<div className={styles.groupWrapper}>
					<div className={`${styles.formGroup}`}>
						<label htmlFor="name" className={errors['first_name'] ? styles.vLabelError : ''}>
							First Name
						</label>
						<input id="name" 
							placeholder="John" 
							className={styles.fs_1Rem} name="first_name" 
							value={this.state.first_name} 
							required="required"
							onChange={(e)=>this.handleChange(e)}
						/>
						<span className={errors['first_name'] ? styles.vError: ''}>
							{errors['first_name']}
						</span>
					</div>
					<div className={`${styles.formGroup}`}>
						<label htmlFor="name" className={errors['last_name'] ? styles.vLabelError : ''}>
							Last Name
						</label>
						<input id="name" placeholder="Doe" className={styles.fs_1Rem} name="last_name" value={this.state.last_name} onChange={(e)=>this.handleChange(e)}/>
						<span className={errors['last_name'] ? styles.vError: ''}>
							{errors['last_name']}
						</span>
					</div>
				</div>
				<div className={styles.groupWrapper}>
					<div className={`${styles.formGroup}`}>
						<label htmlFor="phone_number" className={errors['phone_number'] ? styles.vLabelError : ''}>
							Phone No.
						</label>
						<input id="name" placeholder="202-555-0506" 
							className={styles.fs_1Rem} 
							required="required"
							name="phone_number" 
							value={this.state.phone_number} 
							onChange={(e)=>this.handleChange(e)}
						/>
						<span className={errors['phone_number'] ? styles.vError: ''}>
							{errors['phone_number']}
						</span>
					</div>
					<div className={`${styles.formGroup}`}>
						<label htmlFor="email" className={errors['email'] ? styles.vLabelError : ''}>
							Email
						</label>
						<input id="email" 
						placeholder="example@email.com" 
							required="required"
							className={styles.fs_1Rem} name="email" 
							value={this.state.email} 
							onChange={(e)=>this.handleChange(e)}
						/>
						<span className={errors['email'] ? styles.vError: ''}>
							{errors['email']}
						</span>
					</div>
				</div>
				<div className={styles.groupWrapper}>
					<div className={`${styles.formGroup} ${styles.textArea}`}>
						<label htmlFor="message" className={errors['message'] ? styles.vLabelError : ''}>
							Message
						</label>
						<textarea rows="5" id="message" 
							placeholder="Your message here..."
							required="required" 
							className={styles.fs_1Rem} 
							name="message" 
							value={this.state.message} 
							onChange={(e)=>this.handleChange(e)}
						/>
						<span className={errors['message'] ? styles.vTextError: ''}>
							{errors['message']}
						</span>
					</div>
				</div>
				<button className={`${styles.btn}`}>Send</button>
			</form>
		)
	}
}

export default Contact