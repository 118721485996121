import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout'
import Contact from '../components/contact/index'

const AboutPage = () => {
	return(
		<Layout>
		  <SEO title="Contact" />
		  <Contact/>
		</Layout>
	)
}

export default AboutPage
